import React from 'react'

// App components
import ContentAndSignupForm from '../../components/ContentAndSignupForm'
import MainHelmet from '../../components/MainHelmet'

// App content
import alliedStaff from '../../assets/mayo-landing/allied-health-staff.png'
import { FreeReportDetails } from '../../components/FreeReportDetails'

class Page extends React.Component {
  render() {
    const content = (
      <div>
        <p css={{ marginBottom: 0 }}>
          Are you a Mayo Allied Staff Member, Resident or Fellow?
        </p>
        <h2>You are Eligible for Extensive Orthodontic Benefits</h2>
        <p>
          If you are Allied Staff, then you are eligible to sign up for the
          following orthodontic plans with the following benefits:
        </p>
        <ul css={{ listStyle: 'none' }}>
          <li>
            Standard Delta Dental: <strong>$1,500</strong> Orthodontic Benefit
          </li>
          <li>
            Delta Dental Deluxe: <strong>$2,500</strong> Orthodontic Benefit
          </li>
          <li>
            MRA: <strong>$1,500</strong> Orthodontic Benefit
          </li>
        </ul>
        <p>
          For more information about your Mayo orthodontic benefit, request the{' '}
          <strong>FREE</strong> report above.
        </p>
      </div>
    )
    return (
      <div>
        <MainHelmet
          description={`If you are Allied Staff, then you are eligible to sign up for an orthodontic plan with phenomenal benefits`}
          title={`You are Eligible for Extensive Orthodontic Benefits`}
        />
        <ContentAndSignupForm
          thumbnail={alliedStaff}
          mainTitle={`Top 5 Things You Must Know About Your Orthodontic Benefit`}
          title={'Mayo Allied Staff, Residents and Fellows Edition'}
          content={content}
          postContent={<FreeReportDetails />}
          configKey="mayoResidents"
          captchaId="recaptcha_ddfe774887ee33c95c59d91a8283d547"
        />
      </div>
    )
  }
}

export default Page
